import { getMessages } from '@cocoonspace/i18n/lib/get-messages'
import { defaultI18nNs } from '@cocoonspace/shared/config/default-i-18-n-ns-config'
import { buttonVariants } from '@cocoonspace/ui/components/button'
import { HeaderIcon } from '@cocoonspace/ui/components/header-icon'
import { SearchIcon } from 'lucide-react'
import type { GetStaticProps } from 'next'
import { useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import Link from 'next/link'
import { FindACocoonBtn } from '~/components/nav-bar/find-a-cocoon-btn'
import { AppNavbar } from '~/components/nav-bar/nav-bar'

const Page = () => {
	const { t } = useTranslation()

	return (
		<>
			<AppNavbar>
				<FindACocoonBtn />
			</AppNavbar>

			<div className='flex min-h-[60vh] flex-col items-center justify-center gap-4'>
				<HeaderIcon>
					<SearchIcon size={26} />
				</HeaderIcon>

				<p className='font-bold text-lg'>{t('common:404.title')}</p>

				<Link
					href='/paris'
					className={buttonVariants({ variant: 'outline' })}
				>
					{t('cwNavbar:findCocoon')}
				</Link>
			</div>
		</>
	)
}

export default Page

export const getStaticProps: GetStaticProps = async ({ locale }) => ({
	props: {
		messages: await getMessages(locale as string, ['siteFooter']),
		...(await serverSideTranslations(locale as string, [...defaultI18nNs])),
	},
})
